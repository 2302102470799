<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="合同号"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.pno"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="状态"
            ><el-select
              placeholder="请选择状态"
              v-model.trim="conditions.loanStatus"
              clearable
            >
              <el-option
                v-for="(item, index) in enumStatus"
                :key="item.value"
                :value="index"
                :label="item.label"
              ></el-option> </el-select
          ></el-form-item>
          <el-form-item label="开始时间" prop="loanBegin">
            <el-date-picker
              v-model="conditions.leaseBegin"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="loanEnd">
            <el-date-picker
              v-model="conditions.leaseEnd"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" class="buttons" @click="handleAdd"
              >新增合同</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          border
          :data="tableList"
          style="width: 100%"
          ref="tableRef"
          row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="合同号"
            prop="loanContract"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="贷款公司"
            prop="loanCompany"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="贷款开始时间"
            prop="loanBegin"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="贷款结束时间"
            prop="loanEnd"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="贷款金额（元）"
            prop="loanAmount"
          ></el-table-column>
          <el-table-column show-overflow-tooltip label="合同状态">
            <template slot-scope="scope">
              <el-tag
                v-show="scope.row.loanStatus"
                size="small"
                :type="enumStatus[scope.row.loanStatus]?.type"
                >{{ enumStatus[scope.row.loanStatus]?.label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="handleEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row.id)"
                style="color: rgb(254, 90, 36)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
        <AddLoanContract
          v-if="showAdd"
          ref="addContactsRef"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        ></AddLoanContract>
      </div>
    </div>
  </div>
</template>
<script>
import AddLoanContract from "./addLoanContract.vue";
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      enumStatus: {
        1: { label: "待生效", type: "warning" },
        2: { label: "生效中", type: "success" },
        3: { label: "到期", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 10,
        loanContract: "",
        loanStatus: "",
        loanBegin: "",
        loanEnd: "",
      },
      total: 0,
      tableList: [],
      showAdd: false,
    };
  },
  components: {
    Pagination,
    AddLoanContract,
  },
  created() {
    this.getList();
  },
  methods: {
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/Loan/list",
        "get",
        this.conditions
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    handleAdd() {
      this.showAdd = true;
    },
    handleConfirm() {
      this.showAdd = false;
      this.getList();
    },
    handleCancel() {
      this.showAdd = false;
    },
    handleEdit(row) {
      this.showAdd = true;
      this.$nextTick(() => {
        this.$refs.addContactsRef.id = row.id;
        this.$refs.addContactsRef.isEdit = true;
        for (let key in this.$refs.addContactsRef.form) {
          this.$refs.addContactsRef.form[key] = row[key];
        }
      });
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该联系人, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/asset/Loan/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
    async handleCommand(command) {
      if (command == "a") {
        this.$download("post", "/api/asset/Loan/listExport", this.conditions);
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/asset/Loan/listExport", {
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
