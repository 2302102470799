<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="30%"
    :close-on-click-modal="false"
    :title="isEdit ? '编辑合同' : '添加合同'"
    @close="cancel"
  >
    <el-form
      size="small"
      label-width="100px"
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="合同号" prop="loanContract">
        <el-input v-model="form.loanContract"></el-input>
      </el-form-item>
      <el-form-item label="贷款公司" prop="loanCompany">
        <el-input v-model="form.loanCompany"></el-input>
      </el-form-item>
      <el-form-item label="开始时间" prop="loanBegin">
        <el-date-picker
          v-model="form.loanBegin"
          value-format="yyyy-MM-dd"
          :picker-options="editBeginOptions"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="loanEnd">
        <el-date-picker
          v-model="form.loanEnd"
          :picker-options="editEndOptions"
          value-format="yyyy-MM-dd"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="合同金额" prop="loanContract">
        <el-input v-model="form.loanAmount"></el-input>
      </el-form-item>
      <el-form-item>
        <img-upload
          class="img-upload"
          title="上传附件"
          v-model="form.attachUrl"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="isEdit"
        class="buttons"
        size="small"
        type="primary"
        @click="confirmUpdate"
        >确 定</el-button
      >
      <el-button
        v-else
        class="buttons"
        size="small"
        type="primary"
        @click="confirmAdd"
        >确 定</el-button
      >
      <el-button class="buttons" size="small" @click="cancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import ImgUpload from "@/components/Img-upload.vue";
export default {
  data() {
    return {
      dialogVisible: true,
      isEdit: false,
      id: "",
      form: {
        loanCompany: "",
        loanBegin: "",
        loanEnd: "",
        loanAmount: "",
        loanContract: "",
        attachUrl: "",
      },
      editBeginOptions: {
        disabledDate: (time) => {
          if (!this.form.loanEnd) {
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            let endTime = new Date(this.form.loanEnd);
            endTime.setDate(endTime.getDate() - 1);
            return time.getTime() > endTime;
          }
        },
      },
      editEndOptions: {
        disabledDate: (time) => {
          //开始时间可以和结束时间相同增加
          let startTime = new Date(this.form.loanBegin);
          startTime.setDate(startTime.getDate());
          return (
            time.getTime() < startTime ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      rules: {
        loanCompany: [
          {
            required: true,
            message: "请输入租赁公司名称",
            trigger: ["blur", "change"],
          },
        ],
        loanBegin: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: ["blur", "change"],
          },
        ],
        loanEnd: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: ["blur", "change"],
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    ImgUpload,
  },
  methods: {
    confirmAdd() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/Loan/save",
            "post",
            this.form
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },

    confirmUpdate() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/Loan/update",
            "post",
            {
              id: this.id,
              ...this.form,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
